<template>
    <div class="text-gray-600 py-1 mb-2" :class="column.field=='_alaaqat_select'?'flex gap-4':''">
        <div :id="column.labelObject?.id" class="mb-2 font-extrabold"
             :class="[column.labelObject?.class, column.sort ? 'cursor-pointer'
                 : column.field=='_add_column'? 'last-cell': '']"
             @click="() => sortByColumn(column)">

            <template v-if="$slots[`label:`+column.field]">
                <slot :name="`label:`+column.field" :column="column" :column-index="index"/>
            </template>
            <template v-else>
                {{ column.label }}
            </template>

            <button v-if="column.sort" class="sort-btn">
                <icon v-show="sortingDirection === 'asc'" icon="mdi:sort-ascending"/>
                <icon v-show="sortingDirection === 'desc'" icon="mdi:sort-descending"/>
            </button>
        </div>

        <template v-if="$slots['cell:'+column.field]">
            <slot :name="'cell:'+column.field" :item="row" :row-index="index"/>
        </template>
        <template v-else-if="column.type==='date'&& defaultContent">
            {{ DateHelper.fromIso8601String(defaultContent as string).toDateTimeString() }}
        </template>
        <template v-else>
            {{ defaultContent }}
        </template>
    </div>
</template>

<script setup lang="ts">

import {objectT} from "@lang/t";
import type {Column} from "@components/tables/PrimaryTableTypes";
import {computed} from "vue";
import {get} from "lodash-es";
import {Icon} from "@iconify/vue";
import {ref} from "vue";
import {UrlHelper} from "@js/helpers/UrlHelper";
import {DateHelper} from "@js/helpers/DateHelper";

const props = defineProps<{
    index: number
    column: Column
    row: Record<string | number, any>
}>();

let defaultContent = computed(() => {
    let value = get(props.row, props.column.field)

    return props.column.translate && value
        ? (objectT(value))
        : value
})

const emits = defineEmits(['sort'])

let urlHelper = new UrlHelper()

let sortingDirection = ref<'asc' | 'desc'>(urlHelper.get('direction') ?? undefined)

function sortByColumn(column: Column) {
    if (!column.sort) return;
    emits('sort', column);
}
</script>

<style scoped lang="scss">
.last-cell:last-child {
    margin-top: 5px;
    display: flex;
    justify-content: center;
    height: 40px;
    position: sticky;
}
</style>
