export default {
    'bundle_name': 'إسم الحزمة',
    'new_bundle': 'حزمة جديدة',
    'no_added_platforms': 'لا يوجد منصات مُضافة',
    'choosePlatformToIntegrate': 'إختر المنصة المُراد التكامل معها',
    'conversations_bundles': 'المحادثات',
    'fb_authorization_expired': 'انتهت مدة التوثيق مع فيسبوك',
    'fb_not_authorized': 'لم سمح فيسبوك لنا بجلب البيانات، يرجى المحاولة مرة أخرى',
    'fb_unknown': 'قد لا تكون متصلاً بفيسبوك من خلال هذا المتصفح، يرجى تسجيل الدخول في فيسبوك ثم المحاولة مرة أخرى.',
    'youCantDeleteWaba': "لا يمكنك حذف هذا العنصر قبل حذف أرقامه أولاً",
    'needsReauthenticate': 'إضغط هنا لإعادة المصادقة',
    'newPlatform': 'منصة جديدة',
    'youShouldAddPlatforms': 'يجب إضافة منصات محادثة لبدء إستلام المحادثات،',
    'toAddSomePlatforms': 'لإضافة بعض المنصات.',
    'pageAlreadyExistOnDifferentAccount': 'الصفحة {{page}} مُضافة بحساب آخر.',
    'wabaAlreadyExistOnDifferentAccount': 'حساب الواتس {{waba}} مُضاف بحساب آخر.',
    'instagramAlreadyExistOnDifferentAccount': 'حساب الإنستاجرام {{instagram}} مُضاف بحساب آخر.',
    'platform_is_in_another_account': 'المنصة {{platform}} موجودة بحساب آخر، لا يمكنك تكامل نفس منصة مع أكثر من حساب.',
    'youNeedToActivateYourPlatform': 'لتبدأ بإستلام الرسائل من منصة بعد الإقتران بها يجب عليك تفعيلها، لتفعيل منصة إضغط على أيقونه الـ',
    'youWillLoseAccessToMessages': "تعطيل هذه المنصة يعني أنك ستفقد الوصول إلى رسائلها الحالية. هل أنت متأكد من أنك تريد المضي قدمًا في هذا الإجراء؟",
    'you_need_to_reintegrate': 'هنالك صلاحيات مفقودة بالتكامل مع هذه المنصة، يجب عليك إجراء التكامل مرة أخرى لكي تعمل بالشكل الصحيح. إضغط هنا لإجراء التكامل مرة أخرى.',
    'inbox':'صندوق الوارد',
    'predefined_responses':'الردود المُسبقة',
    'predefined_responses_desc':'رسائل مكتوبة مسبقًا يمكن استخدامها بسرعة للرد على الأسئلة أو المشكلات أو الاستفسارات الشائعة في المحادثات.',
    'broadcast':'النشر',
    'broadcast_desc': 'يتيح لك النشر إرسال رسالة أو ملف إلى عدة جهات اتصال في وقت واحد، ويمكنك أيضًا استخدامه لبدء محادثة مع أحد العملاء.',
    'send_broadcast':'أُنشُر',
    'new_template':'قالب جديد',
    'new_response':'رد جديد',
    'search_title':'البحث حسب العنوان',
    'seen': 'شوهد',
    'reply': 'رد',
    'text': 'النص',
    'template': 'قالب',
    'templates': 'القوالب',
    'broadcast_templates': 'قوالب النشر',
    'broadcast_template': 'قالب النشر',
    'select_template': 'إختر القالب',
    'human_agent_usage_warning': 'إرسال محتوى ترويجي بعد إطار الـ 24 ساعة يخالف سياسات ميتا، مما قد يؤدي إلى فرض قيود على قدرة الصفحة أو حساب الإنستغرام على إرسال الرسائل.',
    'send_tagged_message': 'ارسل رسالة مُعلمة',
    'tagged_message_use': 'الرسائل المُعلمة تسمح لك بإرسال رسالة خارج إطار وقت الرد. هذه الرسائل عبارة عن تحديثات شخصية ذات صلة بشخص ما.',
    'tagged_message_policy': 'لا يجوز استخدام الرسائل المُعلمة لإرسال محتوى ترويجي، بما في ذلك على سبيل المثال لا الحصر: الصفقات والعروض والكوبونات والخصومات. قد يؤدي استخدام علمات الرسائل خارج حالات الاستخدام المعتمدة إلى فرض قيود على قدرة الصفحة أو حساب الإنستغرام على إرسال الرسائل.',
    'allowed_usage': 'الاستخدامات المسموح بها',
    'disallowed_usage': 'الاستخدامات غير المسموح بها (غير شاملة)',

    'ACCOUNT_UPDATE_allowed_usage': [
        'إشعار بتغيير حالة طلب ما، مثل بطاقة ائتمانية أو طلب وظيفة.',
        'إشعار بنشاط مشبوه، مثل تنبيهات الاحتيال.'
    ],
    'ACCOUNT_UPDATE_disallowed_usage': [
        'المحتوى الترويجي، بما في ذلك على سبيل المثال لا الحصر: الصفقات والعروض والكوبونات والخصومات. المحتوى المتكرر (على سبيل المثال: كشف الحساب جاهز، الفاتورة مستحقة، فرص عمل جديدة).',
        'توجيهات لأي استطلاع رأي أو تصويت أو مراجعات لا علاقة لها بتفاعل سابق في رسائل فيسبوك.'
    ],
    'CONFIRMED_EVENT_UPDATE_allowed_usage': [
        'تذكير بدورة أو موعد أو مناسبة مقبلة قام المستخدم بحجزها.',
        'تأكيد لحجز المستخدم أو حضوره لمناسبة أو موعد مقبول.',
        'إشعار بنقل المستخدم أو رحلته المجدولة، مثل الوصول أو الإلغاء أو تأخير الأمتعة أو تغييرات أخرى في حالة السفر.'
    ],

    'CONFIRMED_EVENT_UPDATE_disallowed_usage': [
        "المحتوى الترويجي، بما في ذلك على سبيل المثال لا الحصر: الصفقات والعروض والكوبونات والخصومات.",
        "المحتوى المتعلق بمناسبة لم يشترك فيها المستخدم (على سبيل المثال: تذكير بشراء تذاكر المناسبة، أو بيع منتجات أخرى، أو جداول الرحلات، إلخ).",
        "رسائل متعلقة بمناسبات سابقة.",
        "توجيهات لأي استطلاع رأي أو تصويت أو مراجعات لا علاقة لها بتفاعل سابق في رسائل فيسبوك."
    ],

    'CUSTOMER_FEEDBACK_allowed_usage': [
        'استطلاع رأي حول دعم الشراء.',
        'استطلاع رأي حول مناسبة.',
        'مراجعات المنتجات.'
    ],

    'CUSTOMER_FEEDBACK_disallowed_usage': [
        'لا يمكن استخدام العلامة إلا مع قالب آراء العملاء. الاستخدام بأي شكل آخر ممنوع وسيؤدي إلى الفشل.'
    ],

    'POST_PURCHASE_UPDATE_allowed_usage': [
        'تأكيد المعاملة، مثل الفواتير أو إيصالات الاستلام.',
        'تحديث حالة الشحنة، مثل المنتج قيد النقل، أو تم شحنه، أو تم تسليمه، أو تأخر.',
        'تحديث حالة يتطلب من المستخدم اتخاذ إجراء بشأن طلب قام المستخدم بإنشائه، مثل بطاقة ائتمان مرفوضة، أو عناصر طلب مؤجل، أو تحديثات أخرى للطلب تتطلب إجراءً من المستخدم.'
    ],

    'POST_PURCHASE_UPDATE_disallowed_usage': [
        "المحتوى الترويجي، بما في ذلك على سبيل المثال لا الحصر: الصفقات والعروض والكوبونات والخصومات.",
        "الرسائل التي تقوم بالبيع المتقاطع أو البيع الإضافي للمنتجات أو الخدمات.",
        "توجيهات لأي استطلاع رأي أو تصويت أو مراجعات لا علاقة لها بتفاعل سابق في رسائل فيسبوك."
    ],

    'document': 'وثيقة',
    'image': 'صورة',
    'interactive': 'تفاعلي',
    'audio': 'صوت',
    'contacts': 'جهات الإتصال',
    'location': 'موقع',
    'sticker': 'ملصق',
    'video': 'فيديو',
    'more_platforms':'المزيد من المنصات',
    'add_button':'إضافة زر',
    'copy_offer_code': 'نسخ رمز العرض',
    'offer_code': 'رمز العرض',
    'see_all_options': 'مشاهدة جميع الخيارات',
    'this_chat_is_outside_the_24_window': 'لا يمكنك إرسال رسالة في هذه المحادثة لأنها خرجت عن إطار الـ 24 ساعة للرد.',
    'this_chat_is_outside_the_14_days_window': 'لا يمكنك إرسال رسالة في هذه المحادثة لأنها خرجت عن إطار الـ 14 أيام للرد.',
    'use_$_instead': "إستخدم {{$}} كبديل.",

    'broadcast_status_APPROVED': 'موافق عليه',
    'broadcast_status_PAUSED':'متوقف مؤقتاً',
    'broadcast_status_PENDING': 'قيد الانتظار',
    'broadcast_status_REJECTED': 'مرفوض',
    'broadcast_status_IN_APPEAL':'قيد الاستئناف',
    'choice_contacts': 'إختر جهات الإتصال',
    'send_to_interacted_contacts': 'إرسال الرسائل فقط إلى جهات الاتصال التي تفاعلت سابقًا مع هذه القناة',
    'watch_for_spam': 'يمكن أن تؤدي الشكاوى من الرسائل العشوائية إلى الإبلاغ عن حساب قناتك',
    'receiver_number': 'عدد المُستقبلين',
    'choose_receivers': 'إختر المسُتقبلين',
    'broadcast_is_running_on_background': 'يتم الآن النشر بالخلفية، سيتم إعلامك بمجرد الانتهاء منه.',
    'bulk_one': "ستتلقى جهة إتصال واحدة هذا المنشور، وستتم محاسبتك مباشرة من Meta. هل أنت متأكد أنك تريد الاستمرار؟",
    'bulk_broadcast_are_you_sure': "ستتلقى {{count}} جهة اتصال هذا المنشور، وستتم محاسبتك مباشرة من Meta. هل أنت متأكد أنك تريد الاستمرار؟",
    'bulk_broadcast_are_you_sure_one': "ستتلقى جهة إتصال واحدة هذا المنشور، وستتم محاسبتك مباشرة من Meta. هل أنت متأكد أنك تريد الاستمرار؟",
    'bulk_broadcast_are_you_sure_two': "ستتلقى حهتان إتصال هذا المنشور، وستتم محاسبتك مباشرة من Meta. هل أنت متأكد أنك تريد الاستمرار؟",
    'bulk_broadcast_are_you_sure_few': "ستتلقى {{count}} جهات إتصال هذا المنشور، وستتم محاسبتك مباشرة من Meta. هل أنت متأكد أنك تريد الاستمرار؟",
    'template_reject_reason': 'سبب الرفض',
    'template_example': "مثال",
    'template_example_desc': "(سيتم استخدامه من قبل ميتا للموافقة على قالبك؛ يمكنك تغييره عند الإرسال)",
    'go_to_share_source': 'إذهب للمصدر',

    'attachment_share': 'شارك معك',
    'attachment_mention': 'قام بذكرك',
    'attachment_reply': 'فام بالرد على',

    // message error messages
    '131051': 'لا يمكن عرض الرسالة، نوع الرسالة غير مدعوم حاليًا.',

    // broadcast reject reasons @see https://developers.facebook.com/docs/whatsapp/business-management-api/webhooks/components/
    "ABUSIVE_CONTENT": "تم تحديد أن المحتوى مسيء",
    "INCORRECT_CATEGORY": "تم تحديد أن الفئة غير صحيحة",
    "INVALID_FORMAT": "محتوى مكرر أو ينقصه الأمثلة",
    "SCAM": "تم تحديد أن المحتوى عملية احتيال",

    "whatsapp_broadcast_reject_solution": "يمكنك تحرير القالب وإعادة التقديم للمراجعة. أو، إذا كنت تعتقد أن هذا غير صحيح، يمكنك طلب مراجعة في <a target='_blank' href='https://business.facebook.com/business-support-home'>دعم الأعمال.</a>",

    "sync_last_messages":"مزامنة أحدث الرسائل والعملاء لهذه الصفحة",

    'sent_by': 'أُرسل بواسطة {{fullname}}',
    'channels':'القنوات',
    'add_url_button': 'إضافة زر رابط',
    'add_default_action': 'إضافة إجراء إفتراضي',
    "view_instagram_template": "عرض قالب الإنستغرام",

    "multiple_instagram_template_can_be_added": 'يمكن إضافة عناصر متعددة، حيث ستعمل العناصر المتعددة على عرض قوالب قابلة للتمرير أفقيًا. ويدعم الحد الأقصى 10 عناصر.',
    'predefined_response_name_desc': 'لن يتم إرسال هذا الاسم مع الرسالة، فهو فقط لتمييز الرد.',
    'no_more_messages' : 'وصلت لأقدم رسالة.'
}
